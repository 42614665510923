exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-controlcenter-jsx": () => import("./../../../src/pages/controlcenter.jsx" /* webpackChunkName: "component---src-pages-controlcenter-jsx" */),
  "component---src-pages-imprint-jsx": () => import("./../../../src/pages/imprint.jsx" /* webpackChunkName: "component---src-pages-imprint-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-prescription-jsx": () => import("./../../../src/pages/prescription.jsx" /* webpackChunkName: "component---src-pages-prescription-jsx" */),
  "component---src-pages-privacypolicy-jsx": () => import("./../../../src/pages/privacypolicy.jsx" /* webpackChunkName: "component---src-pages-privacypolicy-jsx" */)
}

